"use client";

import React, { useEffect, useRef, useState } from "react";

const LazyLoadBackground = ({ imageUrl, className, style, lazy = true }) => {
  const ref = useRef(null);
  const [isLoaded, setIsLoaded] = useState(!lazy);

  useEffect(() => {
    if (!lazy) {
      return;
    }

    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsLoaded(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [lazy]);

  return (
    <div
      ref={ref}
      className={className}
      style={{
        ...style,
        backgroundImage: isLoaded ? `url(${imageUrl})` : "none",
      }}
    ></div>
  );
};

export default LazyLoadBackground;
