"use client";

import { PrismicRichText } from "@prismicio/react";
import { Icon } from "@iconify/react";

//tailwindUI imports
import { useEffect, useState } from "react";
import {
  TabGroup,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Transition,
} from "@headlessui/react";
import clsx from "clsx";

import { Container } from "@/components/Container";
import { asText } from "@prismicio/client";
import LazyLoadBackground from "@/components/LazyLoadBackground";

export default function FeaturesHorizontal({ slice, rightSide = false }) {
  let [tabOrientation, setTabOrientation] = useState("horizontal");
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? "horizontal" : "vertical");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  function CheckIcon({ className }) {
    return (
      <svg
        aria-hidden="true"
        className={clsx(
          "h-6 w-6 flex-none fill-current stroke-current",
          className
        )}
      >
        <path
          d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
          strokeWidth={0}
        />
        <circle
          cx={12}
          cy={12}
          r={8.25}
          fill="none"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <section
      id={slice.primary.anchor || undefined}
      aria-label="Features"
      className="relative overflow-hidden bg-[#FFEDEB50] py-10 sm:py-20"
    >
      <Container className="relative">
        <div className="max-w-5xl mx-auto text-center xl:max-w-none">
          <PrismicRichText
            field={slice.primary.title}
            components={{
              heading2: ({ children }) => (
                <h2 className="relative mx-auto max-w-4xl font-bold text-3xl tracking-tight text-slate-700 sm:text-4xl">
                  {children}
                </h2>
              ),
            }}
          />
          <PrismicRichText
            field={slice.primary.subtitle}
            components={{
              paragraph: ({ children }) => (
                <p className="relative mx-auto  w-full mt-6 text-base text-gray-700">
                  {children}
                </p>
              ),
            }}
          />
        </div>

        {/* Tabs for larger screens */}
        <div className="hidden lg:block">
          <TabGroup as="div" className="mt-20 max-w-5xl mx-auto">
            {({ selectedIndex }) => (
              <>
                {/* Tabs on Top */}
                <TabList className="flex flex-col gap-y-4 sm:flex-row sm:justify-center sm:gap-x-6 border-b border-gray-200 pb-4">
                  {slice.items.map((feature, featureIndex) => (
                    <Tab
                      key={asText(feature.feature_title)}
                      className={({ selected }) =>
                        clsx(
                          "flex items-center gap-x-2 px-4 py-2 text-base font-medium rounded-md outline-none",
                          selected
                            ? "border-b-2 border-primary text-white bg-primary"
                            : "text-gray-600 hover:text-primary bg-[#FFEDEB]"
                        )
                      }
                      s
                    >
                      {feature.icon && (
                        <Icon icon={feature.icon} className="text-xl" />
                      )}
                      <PrismicRichText field={feature.feature_title} />
                    </Tab>
                  ))}
                </TabList>

                {/* Tab Panels */}
                <TabPanels className="mt-8">
                  {slice.items.map((feature, featureIndex) => (
                    <TabPanel
                      key={asText(feature.feature_title)}
                      className="relative"
                      // We won't rely on style.opacity anymore, because Transition will
                      // handle show/hide for us.
                    >
                      <Transition
                        show={selectedIndex === featureIndex}
                        unmount={false}
                        enter="transition-opacity duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="text-center mx-auto w-full max-w-4xl flex flex-row items-start gap-10">
                          <div className="relative bg-gray-100 w-1/2 h-[350px] rounded-md">
                            <LazyLoadBackground
                              imageUrl={feature.feature_screenshot.url}
                              className="relative object-contain object-center h-full w-full rounded-lg"
                              style={{
                                backgroundPosition: "50% 50%",
                                backgroundSize: "cover",
                              }}
                              lazyLoad={false}
                            />
                          </div>
                          <div className="mt-6 flex w-1/2 content-start">
                            <PrismicRichText
                              field={feature.feature_description}
                              components={{
                                paragraph: ({ children }) => (
                                  <p className="text-lg text-gray-800">
                                    {children}
                                  </p>
                                ),
                                list: ({ children }) => (
                                  <ul
                                    role="list"
                                    className={clsx(
                                      "flex flex-col gap-y-3 text-sm text-slate-700",
                                      feature.feature_description.length > 8
                                        ? "md:grid md:grid-cols-2 md:gap-x-6"
                                        : ""
                                    )}
                                  >
                                    {children}
                                  </ul>
                                ),
                                listItem: ({ text }) => (
                                  <li key={text} className="flex">
                                    <CheckIcon
                                      className={"text-primary w-6 h-6"}
                                    />
                                    <span className="ml-4 text-left">
                                      {text}
                                    </span>
                                  </li>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </Transition>
                    </TabPanel>
                  ))}
                </TabPanels>
              </>
            )}
          </TabGroup>
        </div>

        {/* Collapsible sections for smaller screens */}
        <div className="block lg:hidden mt-20">
          {slice.items.map((feature, featureIndex) => (
            <div key={asText(feature.feature_title)} className="mb-4">
              <input
                type="checkbox"
                id={`feature-${featureIndex}`}
                className="peer hidden"
                checked={openIndex === featureIndex}
                onChange={() =>
                  setOpenIndex(openIndex === featureIndex ? null : featureIndex)
                }
              />
              <label
                htmlFor={`feature-${featureIndex}`}
                className="flex items-center justify-between p-4 bg-[#FFEDEB] rounded-md cursor-pointer"
              >
                <div className="flex items-center gap-x-2">
                  {feature.icon && (
                    <Icon icon={feature.icon} className="text-xl" />
                  )}
                  <PrismicRichText field={feature.feature_title} />
                </div>
                <svg
                  className="w-6 h-6 text-gray-600 peer-checked:rotate-180 transition-transform"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </label>
              <div className="peer-checked:max-h-screen max-h-0 overflow-hidden transition-all duration-500">
                <div className="p-4 bg-white rounded-md">
                  <div className="relative bg-gray-100 w-full h-[300px] rounded-md mb-4">
                    <LazyLoadBackground
                      imageUrl={feature.feature_screenshot.url}
                      className="relative object-contain object-center h-full w-full rounded-lg"
                      style={{
                        backgroundPosition: "50% 50%",
                        backgroundSize: "cover",
                      }}
                      lazyLoad={false}
                    />
                  </div>
                  <PrismicRichText
                    field={feature.feature_description}
                    components={{
                      paragraph: ({ children }) => (
                        <p className="text-lg text-gray-800">{children}</p>
                      ),
                      list: ({ children }) => (
                        <ul
                          role="list"
                          className={clsx(
                            "flex flex-col gap-y-3 text-sm text-slate-700",
                            feature.feature_description.length > 7
                              ? "md:grid md:grid-cols-2 md:gap-x-6"
                              : ""
                          )}
                        >
                          {children}
                        </ul>
                      ),
                      listItem: ({ text }) => (
                        <li key={text} className="flex">
                          <CheckIcon className={"text-primary w-6 h-6"} />
                          <span className="ml-4">{text}</span>
                        </li>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}
