"use client"; // For Next.js 13+ with App Router

import Link from "next/link";
import { usePathname } from "next/navigation";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Breadcrumbs = () => {
  const pathname = usePathname(); // Get the current route
  const pathSegments = pathname.split("/").filter(Boolean); // Split and filter out empty parts

  // Generate breadcrumb paths
  const breadcrumbs = pathSegments.map((segment, index) => {
    const href = "/" + pathSegments.slice(0, index + 1).join("/");
    const label = segment.replace(/-/g, " ");
    return { href, label };
  });

  // Determine the segments to show on mobile
  const mobileBreadcrumbs = breadcrumbs.slice(-2);

  return (
    <nav
      aria-label="breadcrumb"
      className="flex items-center text-[16px] font-medium text-gray-700"
    >
      {/* Home Link */}
      {/* <Link href="/" className="text-primary hover:underline capitalize">
        HOME
      </Link> */}
      {breadcrumbs.map((crumb, index) => (
        <div key={index} className="hidden md:flex items-center">
          {/* Chevron Icon */}
          {index === breadcrumbs.length - 1 ? (
            <span className="text-gray-500 font-bold capitalize">
              {crumb.label}
            </span>
          ) : (
            <>
              <Link
                href={crumb.href}
                className="text-black hover:underline capitalize"
              >
                {crumb.label}
              </Link>
              <ChevronRightIcon className="h-3 w-5" aria-hidden="true" />
            </>
          )}
        </div>
      ))}
      {mobileBreadcrumbs.map((crumb, index) => (
        <div key={index} className="flex md:hidden items-center">
          {/* Chevron Icon */}
          {index === mobileBreadcrumbs.length - 1 ? (
            <span className="text-gray-500 font-bold capitalize">
              {crumb.label}
            </span>
          ) : (
            <>
              <Link
                href={crumb.href}
                className="text-black hover:underline capitalize"
              >
                {crumb.label}
              </Link>
              <ChevronRightIcon className="h-3 w-5" aria-hidden="true" />
            </>
          )}
        </div>
      ))}
    </nav>
  );
};

export default Breadcrumbs;