"use client";

import React, { useState } from "react";
import PropTypes from "prop-types";

export function PostcodeSearch({
  buttonLabel,
  inputPlaceholder = "Enter your postcode",
  id,
  align = "center",
  autoFocus = false,
}) {
  const [postcode, setPostcode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  let alignClasses = "";
  switch (align) {
    case "left":
      alignClasses = "text-left";
      break;
    case "right":
      alignClasses = "text-right";
      break;
    default:
      alignClasses = "mx-auto";
      break;
  }

  const handlePostcodeChange = (e) => {
    setPostcode(e.target.value);
    setErrorMessage(""); // Clear error message when user starts typing
  };

  const handleButtonClick = async () => {
    const formData = new FormData();
    formData.append("vars[]", `code,${postcode}`);

    try {
      const response = await fetch(
        "https://platform.getsetclean.co.uk/ajax/postalcodes",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (data.err) {
        setErrorMessage(data.error);
      } else if (data.redirect) {
        const redirectUrl = new URL(data.redirect);
        redirectUrl.searchParams.append("code", postcode);
        window.location.href = redirectUrl.toString(); // Redirect to the specified URL with the appended query parameter
      } else {
        setErrorMessage(""); // Clear any previous error message
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <div className="w-full">
      <div
        className={`flex items-center gap-2 ${alignClasses} p-1 h-12 bg-white border border-gray-200 rounded-full w-full `}
      >
        <input
          id={id}
          name="postcode"
          type="text"
          placeholder={inputPlaceholder}
          value={postcode}
          onChange={handlePostcodeChange}
          className="flex-grow text-base px-4 text-gray-600 focus:outline-none"
          autoFocus={autoFocus}
        />
        <button
          onClick={handleButtonClick}
          className="bg-primary hover:bg-black text-white text-base font-medium h-full px-4 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-4 "
        >
          {buttonLabel || "Book now"}
        </button>
      </div>
      {errorMessage && (
        <div className="mt-2 text-red-600 text-sm">{errorMessage}</div>
      )}
    </div>
  );
}

PostcodeSearch.propTypes = {
  buttonLabel: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  id: PropTypes.string,
  align: PropTypes.oneOf(["left", "center", "right"]),
};
