"use client";

import { useState } from "react";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Fragment } from "react";
import { Button } from "./Button";
import { PostcodeSearch } from "./PostcodeSearch";

export default function PostcodeDialog({ buttonLabel = "Book now" }) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div className="flex w-full justify-stretch items-center justify-center h-full">
      <Button
        variant="solid"
        color="primary"
        onClick={openModal}
        submit={true}
        className="w-full bg-primary hover:bg-black text-white text-base font-medium h-full px-6 rounded-full transition duration-300 ease-in-out "
      >
        {buttonLabel}
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-70" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <DialogTitle
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Let&apos;s check availability for your area
                  </DialogTitle>
                  <div className="mt-5">
                    <p className="text-sm text-gray-500">
                      Please enter your postcode to check availability in your
                      area and get a quote for your cleaning services.
                    </p>
                  </div>

                  <div className="mt-5">
                    <PostcodeSearch
                      inputPlaceholder="Your postcode"
                      buttonLabel="Check now"
                      autoFocus={true}
                    />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
