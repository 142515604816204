"use client";

// Tailwind imports
import { useState } from "react";
import { PrismicRichText } from "@/components/PrismicRichText";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

export default function FaqCentered({ slice, background = false }) {
  const [openIndex, setOpenIndex] = useState(null); // Track the currently open panel

  return (
    <>
      {/* Centered and Centered With Background */}
      {background === false && (
        <div className="bg-white" id={slice.primary.anchor || undefined}>
          <div className="mx-auto max-w-7xl px-6 py-10 my-10">
            <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
              <PrismicRichText
                field={slice.primary.title}
                components={{
                  heading2: ({ children }) => (
                    <h2
                      id="faq-title"
                      className="text-3xl text-center font-display leading-10 tracking-tight text-gray-900"
                    >
                      {children}
                    </h2>
                  ),
                }}
              />
              <dl className="mt-10 space-y-3 divide-y divide-gray-900/10">
                {slice.items?.map((faq, idx) => (
                  <div key={idx} className="pt-3">
                    <dt>
                      <button
                        onClick={() =>
                          setOpenIndex((prevIndex) =>
                            prevIndex === idx ? null : idx
                          )
                        }
                        className="flex w-full items-center justify-between text-left text-gray-900 py-2 px-4"
                      >
                        <span className="text-base font-semibold leading-7">
                          <PrismicRichText
                            field={faq.question}
                            components={{
                              paragraph: ({ children }) => <p>{children}</p>,
                              hyperlink: ({ children }) => (
                                <a
                                  href="#"
                                  className="hover:text-indigo-30 font-semibold text-blue-500 hover:text-indigo-300"
                                >
                                  {children}
                                </a>
                              ),
                            }}
                          />
                        </span>
                        <span className="ml-6 flex h-7 items-center bg-primary text-white">
                          {openIndex === idx ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </button>
                    </dt>
                    <dd
                      className={`overflow-hidden transition-all duration-300 ease-in-out ${
                        openIndex === idx
                          ? "max-h-[1000px] opacity-100 translate-y-0"
                          : "max-h-0 opacity-0"
                      }`}
                    >
                      <div className="mt-2 px-4">
                        <PrismicRichText field={faq.answer} />
                      </div>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
