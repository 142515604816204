"use client";

import { PrismicRichText } from "@prismicio/react";
import { PrismicNextLink } from "@prismicio/next";
import { usePathname } from "next/navigation";

export default function AvailableCities({ slice }) {
  const pathname = usePathname(); // Get the current route

  if (slice.primary?.items?.length === 0) return null;

  return (
    <section className="mx-auto bg-[#F0F9F7] rounded-md my-10">
      <div className="lg:items-start justify-start max-w-5xl mx-auto p-4 py-20 text-center ">
        <div className="flex w-full flex-col">
          <PrismicRichText
            field={slice.primary?.title}
            components={{
              heading1: ({ children }) => (
                <h1 className="relative text-4xl md:text-5l font-sans font-bold text-[#091612]">
                  {children}
                </h1>
              ),
              heading2: ({ children }) => (
                <h2 className="mx-auto w-full font-bold text-3xl tracking-tight text-slate-700 sm:text-4xl">
                  {children}
                </h2>
              ),
              strong: ({ children }) => (
                <span className="text-primary">{children}</span>
              ),
            }}
          />
        </div>
        <div
          className={`flex flex-col md:flex-row  mt-10 w-full content-center gap-2 md:gap-10`}
        >
          <div
            className={`flex gap-10 flex-row text-center justify-center content-center justify-between w-full`}
          >
            {slice.primary?.items?.map((item, index) => (
              <div
                key={index}
                className={`group w-full mb-0 rounded-lg bg-white hover:bg-primary transition-all duration-300 ease-in-out`}
              >
                {item.link.url && (
                  <PrismicNextLink
                    field={{
                      ...item.link,
                      url: `${pathname}/${item.link.url}`,
                    }}
                    title={`Learn more about our ${item.name} services`}
                  >
                    <div className="shadow-sm rounded-lg p-6 text-center gap-8">
                      <h3 className="text-xl font-normal tracking-tight text-gray-800 group-hover:text-white text-center">
                        {item.name}
                      </h3>
                    </div>
                  </PrismicNextLink>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
